<template>
<div>
<div class="redtitle">
    <span>活动视频</span>
    <span @click="jumpTovideo">>>&nbsp;&nbsp;更多</span>
</div>
  <div class="projectintroduct">
      <ul class="workinfo-list">
          <li v-for="(item) in videolist" :key="item.id" @click="playVideo(item.id)">
             <dt>
                <!-- <video style="object-fit: fill; width: 100%; height: 100%" controls="controls">
                    <source :src="item.video" type="video/mp4">
                 </video> -->
				 <img :src="item.smallImage+'!353x200'" style="width: 100%; height: 100%">
             </dt>
             <dd>
                <p>
                 <span>录入时间：{{item.publishDate | formateData}}</span>
                 <span>浏览次数：{{item.views}}次</span>
                </p> 
                <h2 class="videotitle">{{item.title}}</h2>
             </dd>
			 <div class="biaoqian">
				 <img src="../../../assets/images/组 15.png" alt="">
			 </div>
			 <div class="bofang">
			 	<img src="../../../assets/images/播放.png" alt="">
			 </div>
          </li>
      </ul>
  </div>
</div>
</template>

<script>
export default {
  props: {
    videolist: {
        type: Array,
        default: () => []
    },
	node:''
  },
  name: 'Projectintroduct',
  methods: {
     jumpTovideo (type) {
         this.$router.push({
             path: '/activevideo',query:{type:this.node}
         })
     },
      playVideo(id) {
      this.$router.push({ path: "/videoShow", query: { id } });
    },
  }
}
</script>

<style lang="less" scoped>
.redtitle  {
    cursor: pointer;
    margin-top: 53px;
    font-size: 23px;
    color: #333333;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    span {
        &:nth-child(1) {
        display: flex;
        &::before {
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        background: url('../../../assets/img/title.png') no-repeat center center;
       }
     }
       &:nth-child(2) {
           color: #ABABAB;
           font-size: 17px;
       }
    }
   
}
.projectintroduct {
    min-height: 541px;
    background-color: #ffffff;
    overflow: hidden;
    padding: 26px 23px 41px 18px;
    box-shadow: 0px 0px 18px 0px rgba(14,14,14, 0.13);
    .workinfo-list {
		&:after{
		  content:'';
		  /*这个宽度和子元素宽度一致*/
		  width: 353px;
		}
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
			position: relative;
			padding: 10px;
			// box-sizing: border-box;
			&:hover {
			  transform: translateY(-5px);
			  box-shadow: 5px 5px 5px #ccc;
			}
            width: 353px;
            // height: 200px;
            justify-content: space-between;
            margin-bottom: 93px;
            &:last-child{
                margin-bottom: 0;
            }
			.bofang {
			  position: absolute;
			  left: 50%;
			  top: 110px;
			  transform: translate(-50%, -50%);
			  z-index: 1000;
			}
			.biaoqian {
				position: absolute;
				top: 20px;
				left: 0;
			}
            dt {
                width: 353px;
                height: 200px;
                background-color: #666666;
            }
            dd {
                p{
                color: #333333;
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                margin-top: 6px;
                }
                h2 {
                   width:100%;
                   text-align: center;
                   color: #DB4041; 
                   font-weight: bolder;
                   word-wrap: break-word;
                   word-break: break-all;
                   margin-top: 16px;
                   font-size: 20px;
                }
            }
            
            span {
                color: #ABABAB;
            }
        }
    }
}
</style>